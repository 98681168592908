import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { uploadShopifyProduct, uploadShopifyVariant } from "../../utils/functions";
import { toast } from "react-toastify";

const AllProducts = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    is_on_sale: { value: null, matchMode: FilterMatchMode.EQUALS },
    is_on_closeout: { value: null, matchMode: FilterMatchMode.EQUALS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    margin: { value: null, matchMode: FilterMatchMode.CONTAINS },
    msrp: { value: null, matchMode: FilterMatchMode.CONTAINS },
    your_price: { value: null, matchMode: FilterMatchMode.CONTAINS },
    current_price: { value: null, matchMode: FilterMatchMode.CONTAINS },
    map: { value: null, matchMode: FilterMatchMode.CONTAINS },
    info: { value: null, matchMode: FilterMatchMode.CONTAINS },
    item_description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sub_category_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    manufacturer_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedProducts, setSelectedProducts] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${serverUrl}/get_products.php?offset=0`);
      const data = await response.json();
      if (data.error) {
        toast.error(data.error_msg);
        return;
      }
      setOffset(200);
      const updatedData = data.data.map((item) => {
        if (item.product_id !== null && item.variant_id !== null) {
          item.status = "Uploaded";
        } else if (item.product_id !== null && item.variant_id === null && item.available > 0) {
          item.status = "Upload";
        } else if (item.available > 0) {
          item.status = "Upload";
        } else {
          item.status = "Can't Upload";
        }

        if (item.msrp === "0") {
          item.msrp = (item.your_price / 0.6).toFixed(2);
        }

        if (item.msrp != null && item.your_price != null) {
          item.margin = `${(((item.msrp - item.your_price) / item.msrp) * 100).toFixed(2)}%`;
        } else {
          item.margin = "N/A";
        }
        return item;
      });
      setData(updatedData);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMoreData = async () => {
    try {
      const response = await fetch(`${serverUrl}/get_products.php?offset=${offset}`);
      const data = await response.json();
      if (data.error) {
        toast.error(data.error_msg);
        return;
      }
      const updatedData = data.data.map((item) => {
        if (item.product_id !== null && item.variant_id !== null) {
          item.status = "Uploaded";
        } else if (item.product_id !== null && item.variant_id === null && item.available > 0) {
          item.status = "Upload";
        } else if (item.available > 0) {
          item.status = "Upload";
        } else {
          item.status = "Can't Upload";
        }

        if (item.msrp === "0") {
          item.msrp = (item.your_price / 0.6).toFixed(2);
        }

        if (item.msrp != null && item.your_price != null) {
          item.margin = `${(((item.msrp - item.your_price) / item.msrp) * 100).toFixed(2)}%`;
        } else {
          item.margin = "N/A";
        }
        return item;
      });
      setData((prevData) => [...prevData, ...updatedData]);
      if (data.data.length !== 0) {
        setOffset((prevOffset) => prevOffset + 200);
      } else {
        toast.success("All products fetched successfully");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
      fetchData();
    }, 2000);
  }, []);

  useEffect(() => {
    if (offset > 0) {
      fetchMoreData();
    }
  }, [offset]);

  const uploadProducts = async () => {
    selectedProducts.forEach(async (product) => {
      if (product.status === "Upload") {
        await uploadProduct(product);
      }
    });
  };

  const uploadProduct = async (rowData) => {
    setIsLoading(true);
    try {
      if (rowData.product_id === null && rowData.variant_id === null) {
        let shopifyResponse = await uploadShopifyProduct(rowData);
        if (shopifyResponse.status === 201) {
          setData((prevData) =>
            prevData.map((item) => {
              if (item.id === rowData.id) {
                return {
                  ...item,
                  status: "Uploaded",
                  inventory_item_id: shopifyResponse.response.product.variants[0].inventory_item_id,
                  variant_id: shopifyResponse.response.product.variants[0].id,
                  product_id: shopifyResponse.response.product.id,
                };
              }
              if (item.group_id === rowData.group_id) {
                return {
                  ...item,
                  product_id: shopifyResponse.response.product.id,
                };
              }
              return item;
            })
          );
        }
      } else if (rowData.product_id !== null && rowData.variant_id === null) {
        let shopifyResponse = await uploadShopifyVariant(rowData);
        if (shopifyResponse.status === 200) {
          setData((prevData) =>
            prevData.map((item) => {
              if (item.id === rowData.id) {
                return {
                  ...item,
                  status: "Uploaded",
                  inventory_item_id: shopifyResponse.response.variant.inventory_item_id,
                  variant_id: shopifyResponse.response.variant.id,
                  product_id: shopifyResponse.response.variant.product_id,
                };
              }
              return item;
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const statusTemplate = (rowData) => {
    switch (rowData.status) {
      case "Uploaded":
        return <span className={"bg-success text-white p-1 btn btn-lg"}>{rowData.status}</span>;
      case "Upload":
        return (
          <Button
            label="Upload"
            className="btn btn-dark btn-lg"
            onClick={() => uploadProduct(rowData)}
            disabled={isLoading}
          />
        );
      case "Can't Upload":
        return <span className={"bg-danger text-white p-1 btn btn-lg"}>{rowData.status}</span>;
      default:
        return <span className={"bg-secondary text-white p-1 btn-lg"}>{rowData.status}</span>;
    }
  };

  const priceTemplate = (rowData) => `$${rowData.your_price}`;

  const stockTemplate = (rowData) =>
    rowData.available > 0 ? (
      <span className="btn btn-lg bg-success text-white">In Stock: {rowData.available}</span>
    ) : (
      <span className="btn btn-lg bg-danger text-white">Out of Stock</span>
    );

  const saleTemplate = (rowData) =>
    rowData.is_on_sale === "1" ? (
      <span className="bg-success text-white btn btn-lg">Yes</span>
    ) : (
      <span className="bg-danger text-white btn btn-lg">No</span>
    );

  const closeoutTemplate = (rowData) =>
    rowData.is_on_closeout === "1" ? (
      <span className="bg-success text-white btn btn-lg">Yes</span>
    ) : (
      <span className="bg-danger text-white btn btn-lg">No</span>
    );

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`http://www.bti-usa.com/images/pictures${rowData.image_path}`}
        alt={rowData.image}
        width="325px"
        className="shadow-4"
      />
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value.trimStart().replace(/\s+/g, " ");
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilter(value);
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={["Uploaded", "Upload", "Can't Upload"]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select..."
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const booleanRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={[
          { label: "Yes", value: 1 },
          { label: "No", value: 0 },
        ]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select..."
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  return (
    <div className="row mt-2">
      <div className="col-12">
        <div className="card" style={{ overflowX: "auto" }}>
          <div className="card-body">
            <h4 className="mt-0 header-title">All products</h4>

            <div className="row mb-3">
              <div className="col-md-6">
                <span className="p-input-icon-left">
                  <InputText
                    value={globalFilter}
                    onChange={onGlobalFilterChange}
                    placeholder="Search Category Products..."
                    className="p-inputtext-sm"
                  />
                </span>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                {selectedProducts.length != 0 && (
                  <Button
                    label="Upload Selected"
                    className="btn btn-success btn-lg"
                    onClick={() => uploadProducts()}
                    disabled={isLoading}
                  />
                )}
              </div>
            </div>

            <DataTable
              value={data}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              globalFilter={globalFilter}
              filters={filters}
              filterDisplay="row"
              emptyMessage="No products found."
              columnResizeMode="expand"
              resizableColumns
              showGridlines
              loading={isLoading}
              selectionMode={"checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column header="Image" body={imageBodyTemplate} />
              <Column
                sortable
                field="status"
                header="On Website"
                showFilterMenu={false}
                filter
                filterElement={statusRowFilterTemplate}
                body={statusTemplate}
              />
              <Column
                sortable
                field="is_on_sale"
                header="On Sale"
                showFilterMenu={false}
                filter
                filterElement={booleanRowFilterTemplate}
                body={saleTemplate}
              />
              <Column
                sortable
                field="is_on_closeout"
                header="On Closeout"
                showFilterMenu={false}
                filter
                filterElement={booleanRowFilterTemplate}
                body={closeoutTemplate}
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="id"
                header="Product ID"
                body={(rowData) => {
                  return (
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/product?id=" + rowData.id);
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      {rowData.id}
                    </a>
                  );
                }}
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="margin"
                header="Margin"
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="msrp"
                header="Msrp"
                body={(rowData) => `$${rowData.msrp}`}
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="your_price"
                header="Your Price"
                body={priceTemplate}
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="current_price"
                header="Current Price"
                className="no-wrap"
                body={(rowData) => `$${rowData.current_price}`}
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="map"
                header="MAP"
                body={(rowData) => `$${rowData.map}`}
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="info"
                header="Info"
                body={stockTemplate}
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="item_description"
                header="Product Name"
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="category_name"
                header="Category"
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="sub_category_name"
                header="Subcategory"
              />
              <Column
                sortable
                filter
                filterPlaceholder="Search..."
                field="manufacturer_name"
                header="Manufacturer"
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProducts;

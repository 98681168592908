import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DataView from "../utils/DataView";

const SearchedProducts = () => {
  const location = useLocation();
  const [data, setData] = useState(location.state?.data || []);

  useEffect(() => {
    setData(location.state?.data || []);
  }, [location.state?.data]);

  return (
    <>
      <DataView productData={data} title="Searched Products" />
    </>
  );
};

export default SearchedProducts;

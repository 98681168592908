import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import LoadingScreen from "../../Loading/loading";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

const Tables = ({ data }) => {
  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    if (Array.isArray(data)) {
      setTableData(data);
    }
  }, [data]);

  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value.trim());
  };

  if (!data || data.length === 0) {
    return <LoadingScreen />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card" style={{ overflowX: "auto" }}>
          <div className="card-body">
            <h4 className="mt-0 header-title">Top Products with Highest Margin</h4>
            <p className="text-muted mb-3">The Top Products with the Highest Margins</p>

            <div className="mb-3 flex justify-end">
              <span className="p-input-icon-left">
                <InputText
                  value={globalFilter}
                  onChange={onGlobalFilterChange}
                  placeholder="Search..."
                  className="p-inputtext-sm"
                />
              </span>
            </div>

            <DataTable
              value={tableData}
              paginator
              rows={10}
              globalFilter={globalFilter}
              globalFilterFields={[
                "id",
                "margin",
                "your_price",
                "msrp",
                "category_name",
                "sub_category_name",
                "manufacturer_name",
                "item_description",
              ]}
              emptyMessage="No records found."
              tableStyle={{ minWidth: "60rem" }}
              className="border border-gray-300 rounded-lg shadow custom-table font-small"
              showGridlines
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Column
                field="id"
                header="ID"
                headerClassName="bg-blue-500"
                className="text-center"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              ></Column>
              <Column
                field="margin"
                header="Margin"
                body={(rowData) => {
                  if (rowData.margin >= 0) {
                    return `$${rowData.margin}`;
                  } else {
                    return "$0.00";
                  }
                }}
                className="text-gray-700"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                sortable
              ></Column>
              <Column
                field="your_price"
                header="Price"
                body={(rowData) => `$${rowData.your_price}`}
                className="font-semibold"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                sortable
              ></Column>
              <Column
                field="msrp"
                header="MSRP"
                body={(rowData) => `$${rowData.msrp}`}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                sortable
              ></Column>
              <Column
                field="category_name"
                header="Category"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                sortable
              ></Column>
              <Column
                field="sub_category_name"
                header="Sub Category"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                sortable
              ></Column>
              <Column
                field="manufacturer_name"
                header="Manufacturer"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                sortable
              ></Column>
              <Column
                field="item_description"
                header="Description"
                className="italic"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tables;

import { Route, Routes } from "react-router-dom";
import CategoryProducts from "./components/categoryProducts";
import ExistingProducts from "./components/existing-products";
import RemovedProducts from "./components/removed-products";
import SaleProducts from "./components/SaleProducts";
import Dashboard from "./components/dashboard";
import LoginPage from "./components/Login";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import ChangePassword from "./components/changePassword";
import ForgotPasswordPage from "./components/forgot-password";
import ResetPasswordPage from "./components/reset-password";
import Orders from "./components/orders";
import MerchantProducts from "./components/merchantProducts";
import Product from "./components/product";
import ManufacturerProducts from "./components/manufacturerProducts";
import SearchedProducts from "./components/searchedProducts";
import AllProducts from "./components/allProducts";
import { Provider } from "react-redux";
import store from "./store/store";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/existing-products"
            element={
              <ProtectedRoute>
                <ExistingProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/removed-products"
            element={
              <ProtectedRoute>
                <RemovedProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sale-products"
            element={
              <ProtectedRoute>
                <SaleProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/products/:category_id/:subcategory_id/:manufacturer_id"
            element={
              <ProtectedRoute>
                <CategoryProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manufacturer-products/:manufacturer_id/:category_id/:subcategory_id"
            element={
              <ProtectedRoute>
                <ManufacturerProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/merchant-products"
            element={
              <ProtectedRoute>
                <MerchantProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product"
            element={
              <ProtectedRoute>
                <Product />
              </ProtectedRoute>
            }
          />
          <Route
            path="/all-products"
            element={
              <ProtectedRoute>
                <AllProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search-results"
            element={
              <ProtectedRoute>
                <SearchedProducts />
              </ProtectedRoute>
            }
          />

          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          />

          <Route path="/forgot-password" element={<ForgotPasswordPage />} />

          <Route path="/reset-password" element={<ResetPasswordPage />} />
        </Routes>
      </AuthProvider>
    </Provider>
  );
}

export default App;

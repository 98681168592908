import React from "react";
import StatisticCard from "./StatesCards/statisticCard";
import ChartCard from "./StatesCards/ChartCard";

const StatsCard = ({
  uploadedProducts,
  notUploaded,
  deletedVariants,
  totalProducts,
  categoryData,
}) => {
  return (
    <>
      <div className="row">
        <StatisticCard
          totalProducts={totalProducts}
          deletedVariants={deletedVariants}
          notUploaded={notUploaded}
          uploadedProducts={uploadedProducts}
        />
      </div>
      <ChartCard categoryData={categoryData} />
    </>
  );
};

export default StatsCard;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import DataView from "../utils/DataView";
import LoadingScreen from "../Loading/loading";

const ExistingProducts = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    toast.info("Please wait, fetching products...");
    try {
      const response = await fetch(`${serverUrl}/get_existing_products.php`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      if (result.error) {
        toast.error(result.error_msg);
        return;
      }
      setData(result.data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>{isLoading ? <LoadingScreen /> : <DataView productData={data} title="Existing Products" />}</>
  );
};

export default ExistingProducts;

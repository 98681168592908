import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../Loading/loading";

const Product = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [data, setData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${serverUrl}/get_product_details.php?id=${id}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error_msg);
          return;
        }
        toast.success("Product fetched successfully");
        setData(data.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  if (!data) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <div className="col-12 mt-3">
          <h2>Product Details</h2>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={`http://www.bti-usa.com/images/pictures/${data.image_path}`}
                    alt={data.item_description}
                    className="img-fluid my-3"
                  />
                </div>
                <div className="col-lg-6 align-self-center">
                  <div className="single-pro-detail">
                    <h3 className="mb-1">{data.item_description}</h3>
                    <div className="custom-border mb-3"></div>
                    <p className="text-muted mb-0">
                      <strong>
                        <a
                          href={`https://www.bti-usa.com/public/item/${id}?track=true`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "underline" }}
                        >
                          View on BTI
                        </a>
                      </strong>
                    </p>
                    <p></p>
                    <p className="pro-price">Your Price : ${data.your_price}</p>
                    <h6 className="text-muted font-13">Features :</h6>
                    <ul className="list-unstyled pro-features border-0">
                      <li>
                        <strong>MSRP:</strong> ${data.msrp}
                      </li>
                      <li>
                        <strong>Margin:</strong> ${(data.msrp - data.your_price).toFixed(2)}
                      </li>
                      <li>
                        <strong>Current Price:</strong> ${data.current_price}
                      </li>
                      <li>
                        <strong>Available Reno:</strong> {data.available_reno}
                      </li>
                      <li>
                        <strong>Available Santa Fe:</strong> {data.available_santa_fe}
                      </li>
                      <li>
                        <strong>Total Available:</strong> {data.available}
                      </li>
                      <li>
                        <strong>Category:</strong> {data.category_name}
                      </li>
                      <li>
                        <strong>Sub-Category:</strong> {data.sub_category_name}
                      </li>
                      <li>
                        <strong>Manufacturer:</strong> {data.manufacturer_name}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;

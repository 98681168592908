import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataView from "../utils/DataView";
import LoadingScreen from "../Loading/loading";

const ManufacturerProducts = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const { category_id, subcategory_id, manufacturer_id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [category_id, subcategory_id, manufacturer_id]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const response = await fetch(
        `${serverUrl}/get_manufacturer_products.php?manufacturer_id=${manufacturer_id}&category_id=${category_id}&sub_category_id=${subcategory_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.error) {
        toast.error(data.error_msg);
        return;
      }
      setData(data.data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <DataView productData={data} title="Manufacturer Products" />
      )}
    </>
  );
};

export default ManufacturerProducts;

import React, { createContext, useState, useEffect } from "react";
import LeftNavbar from "../components/utils/LeftNavbar";
import TopNavbar from "../components/utils/TopNavbar";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css"; /* Core CSS */
import "primeicons/primeicons.css"; /* Icons */
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";


const AuthContext = createContext();

export function AuthProvider({ children }) {

  const [buttonActive, setButtonActive] = useState(true);

  const restrictedUrls = ["/", "/forgot-password", "/reset-password"];
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return !!localStorage.getItem("auth_token");
  });

  const [userId, setUserId] = useState(() => {
    return localStorage.getItem("user_id");
  });

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const storedUserId = localStorage.getItem("user_id");

    if (token && storedUserId) {
      setIsAuthenticated(true);
      setUserId(storedUserId);
    }
  }, []);

  const login = (token, userId) => {
    localStorage.setItem("auth_token", token);
    localStorage.setItem("user_id", userId);
    setIsAuthenticated(true);
    setUserId(userId);
  };

  const logout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("tokenID");
    setIsAuthenticated(false);
    setUserId(null);
  };



  if (restrictedUrls.includes(window.location.pathname)) {
    return (
      <AuthContext.Provider value={{ isAuthenticated, userId, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
  } else {
    return (
      <AuthContext.Provider value={{ isAuthenticated, userId, login, logout }}>

        <LeftNavbar buttonActive={buttonActive} />
        <TopNavbar buttonActive={buttonActive} setButtonActive={setButtonActive} />

        <div className="page-wrapper">
          <div
            className={`page-content-tab ${buttonActive ? "active" : "page-content-tab-hide"
              }`}
          >
            {children}
            <footer className="footer text-center text-sm-left">
              &copy; 2024 - 2025 Garage Cycles{" "}
              <span className="text-muted d-none d-sm-inline-block float-right">
                Crafted with <i className="mdi mdi-heart text-danger"></i>
              </span>
            </footer>
          </div>
        </div>
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;

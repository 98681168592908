import React from "react";
import { Doughnut } from "react-chartjs-2";
import "@fortawesome/fontawesome-free/css/all.css";
import "./Chart.css";

const getRandomColor = () => {
  const randomColor = `hsl(${Math.floor(Math.random() * 360)}, 100%, 75%)`;
  return randomColor;
};

const ChartCard = ({ categoryData }) => {
  const totalProducts = categoryData.reduce(
    (acc, item) => acc + item.percentageMargin,
    0
  );

  const labels = categoryData.map((item) => item.name);
  const dataValues = categoryData.map((item) =>
    parseFloat(((item.percentageMargin / totalProducts) * 100).toFixed(2))
  );

  const backgroundColors = categoryData.map(() => getRandomColor());

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: backgroundColors,
        borderWidth: 0,
        hoverOffset: 5,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label || "";
            const value = tooltipItem.raw || 0;
            return `${label}: ${value} %`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    cutout: "40%",
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="chart-card">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <h4 className="mx-2 my-2">Number of Products by Percantage</h4>
            <div className="col-12 chart-container">
              <Doughnut data={data} options={options} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartCard;

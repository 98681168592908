import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { Alert, CircularProgress } from "@mui/material";
import AuthContext from "../../context/AuthContext";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { userId } = useContext(AuthContext);
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirmation do not match.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      const response = await fetch(`${serverUrl}/change_password.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          current_password: currentPassword,
          new_password: newPassword,
        }),
      });

      const data = await response.json();

      if (data.error) {
        toast.error(data.error_msg);
      } else {
        toast.success("Password changed successfully!");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      setMessage("An Error occured. Please Try again");
      toast.error(`An error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col-12">
          <div className="card" style={{ overflowX: "auto" }}>
            <div
              className="card-body"
              style={{
                paddingBottom: "0px",
              }}
            >
              <h4 className="mb-3 header-title">Change Password</h4>
            </div>

            <div className="px-4">
              {message && (
                <Alert severity={message.startsWith("Welcome") ? "success" : "error"}>
                  {message}
                </Alert>
              )}
              <form className="form-horizontal auth-form my-4" onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                  <label className="d-flex">Current Password</label>
                  <div className="input-group mb-3">
                    <span className="auth-form-icon">
                      <i className="dripicons-user"></i>
                    </span>
                    <input
                      type="password"
                      autoComplete="email"
                      autoFocus
                      required
                      className="form-control"
                      placeholder="Enter Password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="d-flex">New Password</label>
                  <div className="input-group mb-3">
                    <span className="auth-form-icon">
                      <i className="dripicons-lock"></i>
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      autoComplete="current-password"
                      value={newPassword}
                      required
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="d-flex">Confirm Password</label>
                  <div className="input-group mb-3">
                    <span className="auth-form-icon">
                      <i className="dripicons-lock"></i>
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      autoComplete="current-password"
                      value={confirmPassword}
                      required
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 row">
                  <div className="col-12 mt-2 text-right">
                    <button
                      className="btn btn-gradient-primary btn-sm waves-effect waves-light"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : <>Change Password</>}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

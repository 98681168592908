import React, { useEffect, useState } from "react";
import "./style.css";

const SearchByManufacturer = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [categories, setCategories] = useState([]);
  const [hoveredAlphabet, setHoveredAlphabet] = useState(null);
  const [hoveredManufacturer, setHoveredManufacturer] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${serverUrl}/get_manufacturers.php`);
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="search-by-manufacturer">
      <div className="manufacturer-list">
        <ul className="list-unstyled d-flex flex-wrap justify-content-center">
          {categories.map((item, index) => (
            <li
              key={index}
              className={`item-text ${item.alphabet === hoveredAlphabet && "active"}`}
              onMouseEnter={() => setHoveredAlphabet(item.alphabet)}
              onMouseLeave={() => setHoveredAlphabet(null)}
            >
              <div>{item.alphabet.toUpperCase()}</div>
              {hoveredAlphabet === item.alphabet && (
                <div className="manufacturer-dropdown">
                  {item.manufacturers.map((manufacturer) => (
                    <div
                      key={manufacturer.manufacturer_id}
                      onMouseEnter={() => setHoveredManufacturer(manufacturer.manufacturer_id)}
                      onMouseLeave={() => {
                        setHoveredManufacturer(null);
                        setHoveredCategory(null);
                      }}
                    >
                      <a
                        className={`${
                          manufacturer.manufacturer_id === hoveredManufacturer && "active_dropdown"
                        }`}
                      >
                        {manufacturer.name}
                      </a>
                      {hoveredManufacturer === manufacturer.manufacturer_id && (
                        <div className="subcategory-dropdown">
                          <a
                            href={`/manufacturer-products/${
                              manufacturer.manufacturer_id
                            }/all/all?manufacturer_name=${encodeURIComponent(
                              manufacturer.name
                            )}&category_name=all&sub_category_name=all`}
                            onMouseEnter={() => setHoveredCategory(null)}
                          >
                            All {manufacturer.name} Categories
                          </a>
                          {manufacturer.categories.map((category) => (
                            <div key={category.category_id}>
                              <a
                                onMouseEnter={() => setHoveredCategory(category.category_id)}
                                className={`${
                                  hoveredCategory === category.category_id && "active_dropdown"
                                }`}
                              >
                                {category.category_name}
                              </a>
                              {hoveredCategory === category.category_id && (
                                <div className="sub-subcategory-dropdown">
                                  <a
                                    href={`/manufacturer-products/${manufacturer.manufacturer_id}/${
                                      category.category_id
                                    }/all?manufacturer_name=${encodeURIComponent(
                                      manufacturer.name
                                    )}&category_name=${encodeURIComponent(
                                      category.category_name
                                    )}&sub_category_name=all`}
                                  >
                                    All {category.category_name} Subcategories
                                  </a>
                                  {category.sub_categories.map((subCategory) => (
                                    <div key={subCategory.sub_category_id}>
                                      <a
                                        href={`/manufacturer-products/${
                                          manufacturer.manufacturer_id
                                        }/${category.category_id}/${
                                          subCategory.sub_category_id
                                        }?manufacturer_name=${encodeURIComponent(
                                          manufacturer.name
                                        )}&category_name=${encodeURIComponent(
                                          category.category_name
                                        )}&sub_category_name=${encodeURIComponent(
                                          subCategory.sub_category_name
                                        )}`}
                                      >
                                        {subCategory.sub_category_name}
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchByManufacturer;

import React, { useEffect } from "react";
import feather from "feather-icons";

const StatisticCard = ({ uploadedProducts, notUploaded, deletedVariants, totalProducts }) => {
  const stats = [
    {
      icon: "shopping-cart",
      value: totalProducts || 0,
      label: "Total Products",
      color: "purple",
    },
    {
      icon: "upload",
      value: uploadedProducts || 0,
      label: "Uploaded Products",
      color: "secondary",
    },
    {
      icon: "trash-2",
      value: deletedVariants || 0,
      label: "Deleted Variants",
      color: "pink",
    },
    {
      icon: "slash",
      value: notUploaded || 0,
      label: "Not Uploaded",
      color: "warning",
    },
  ];

  useEffect(() => {
    feather.replace();
  }, []);

  return (
    <>
      {stats.map((stat, index) => (
        <div className="col-lg-6" key={index}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-4 align-self-center text-center">
                  <i
                    data-feather={stat.icon}
                    className={`align-self-center icon-lg icon-dual-${stat.color}`}
                  ></i>
                </div>
                <div className="col-8 ">
                  <h3 className="mt-0 mb-1 font-weight-semibold">{stat.value}</h3>
                  <p className="mb-0 font-12 text-muted text-uppercase font-weight-semibold-alt">
                    {stat.label}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default StatisticCard;

import React, { useEffect, useRef, useState } from "react";
import EconomyCards from "./Cards/EconomyCards";
import StatsCard from "./Cards/StatsCard";
import Tables from "./Tables/Tables";
import Chart from "chart.js/auto";
import LoadingScreen from "../Loading/loading";
import SearchByManufacturer from "../searchByCategory/index";

const Dashboard = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [categoryData, setCategoryData] = useState([]);
  const [totalProducts, setTotalProducts] = useState("");
  const [deletedVariants, setDeletedVariants] = useState("");
  const [notUploaded, setNotUploaded] = useState("");
  const [uploadedProducts, setUploadedProducts] = useState("");
  const [data, setData] = useState([]);
  const [countOnSale, setCountOnSale] = useState("");
  const [countCloseOut, setCountCloseOut] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef(null);

  useEffect(() => {
    fetchCategoryData();
  }, []);

  useEffect(() => {
    if (!categoryData || categoryData.length === 0) {
      return;
    }

    const ctx = document.getElementById("bar");
    if (!ctx) {
      return;
    }

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: categoryData.map((category) => category.name),
        datasets: [
          {
            label: "Percentage Margin",
            data: categoryData.map((category) => category.percentageMargin),
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [categoryData]);

  const fetchCategoryData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${serverUrl}/get_stats.php`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setTotalProducts(data.data.count_total);
        setDeletedVariants(data.data.count_deleted_variants);
        setNotUploaded(data.data.count_not_uploaded);
        setUploadedProducts(data.data.count_uploaded_variants);
        setData(data.data.products);

        setCountOnSale(data.data.count_on_sale);
        setCountCloseOut(data.data.count_close_out);

        const categories = data.data.categories.map((category) => ({
          name: category.category_name,
          percentageMargin: parseFloat(category.percentage_margin),
        }));
        setCategoryData(categories);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
            <h4 className="page-title">Dashboard</h4>
          </div>
        </div>
      </div>

      {!isLoading && (
        <div>
          <div className="row">
            <div className="col-lg-4">
              <div className="card ">
                <div className="card-body">
                  <h4 className="header-title mt-0">Search by Manufacturer</h4>
                  <SearchByManufacturer />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <div className="card ">
                <div className="card-body">
                  <h4 className="header-title mt-0">Percentage Margin</h4>
                  <div className="tab-content">
                    <div className="tab-pane active pt-3">
                      <canvas id="bar" className="drop-shadow w-100" height="525"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <StatsCard
                totalProducts={totalProducts}
                deletedVariants={deletedVariants}
                notUploaded={notUploaded}
                uploadedProducts={uploadedProducts}
                categoryData={categoryData}
              />
            </div>
          </div>
          <EconomyCards
            countCloseOut={countCloseOut}
            countOnSale={countOnSale}
            totalProducts={totalProducts}
            deletedVariants={deletedVariants}
          />
          <Tables data={data} />
        </div>
      )}
    </div>
  );
};

export default Dashboard;

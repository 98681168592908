import React from "react";

const EconomyCards = ({ countCloseOut, countOnSale, deletedVariants, totalProducts }) => {
  const goals = [
    {
      title: "On sale",
      value: countCloseOut || 0,
      target: totalProducts,
      progress: Math.min((countOnSale / (totalProducts - deletedVariants)) * 100, 100).toFixed(2),
      iconClass: "mdi mdi-bullseye-arrow bg-soft-success",
      progressClass: "bg-success",
      description: "On Sale Products",
    },
    {
      title: "On Close Out",
      value: countOnSale || 0,
      target: totalProducts,
      progress: Math.min((countCloseOut / (totalProducts - deletedVariants)) * 100, 100).toFixed(2),
      iconClass: "mdi mdi-bullseye bg-soft-pink",
      progressClass: "bg-pink",
      description: "Close-Out Products.",
    },
  ];

  return (
    <>
      <div className="row">
        {goals.map((goal, index) => (
          <div className="col-lg-6" key={index}>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-7 align-self-center">
                    <div className="timer-data">
                      <div className="icon-info mt-1 mb-4">
                        <i className={`${goal.iconClass}`}></i>
                      </div>
                      <h3 className="mt-0 text-dark">
                        {goal.value} <span className="font-14">out of {goal.target} products</span>
                      </h3>
                      <h4 className="mt-0 header-title text-truncate mb-1">{goal.title}</h4>
                    </div>
                  </div>
                  <div className="col-5 align-self-center">
                    <div className="mt-4">
                      <span className="text-info">Complete</span>
                      <small className="float-right text-muted ml-3 font-14">
                        {goal.progress}%
                      </small>
                      <div className="progress mt-2" style={{ height: "5px" }}>
                        <div
                          className={`progress-bar ${goal.progressClass}`}
                          role="progressbar"
                          style={{
                            width: `${goal.progress}%`,
                            borderRadius: "5px",
                          }}
                          aria-valuenow={goal.progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EconomyCards;

import React, { useEffect, useState } from "react";
import "./styleproducts.css";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";
import LoadingScreen from "../Loading/loading";

const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const SCOPE = process.env.REACT_APP_SCOPE;
const GOOGEL_API_URL = process.env.REACT_APP_GOOGLE_API;
const SERVER_API_URL = process.env.REACT_APP_SERVER_URL;

const MerchantProducts = () => {
  const [tokenId, setTokenId] = useState(null);
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckToken, setIsCheckToken] = useState(true);
  const [storeShopifyData, setStoreShopifyData] = useState("");
  const [dataTableInstance, setDataTableInstance] = useState(null);

  useEffect(() => {
    fetch(`${SERVER_API_URL}/get_uploaded_products.php`)
      .then((resp) => resp.json())
      .then((data) => {
        setStoreShopifyData(data.data);
        if (localStorage.getItem("tokenID")) {
          setTokenId(localStorage.getItem("tokenID"));
          setIsLoading(true);
          getProducts();
        }
        setIsCheckToken(false);
      })
      .catch((error) => console.log(error));
  }, [tokenId]);

  const handleClick = () => {
    const loadGapiScript = () => {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        initializeGapiClient();
      };
      document.body.appendChild(script);
    };
    const initializeGapiClient = () => {
      gapi.load("client:auth2", initClient);
    };
    const initClient = () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          scope: SCOPE,
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          authInstance.signIn().then(onSuccess).catch(onFailure);
        })
        .catch((error) => {
          console.error("Error loading GAPI client: ", error);
        });
    };
    loadGapiScript();
  };

  const getProducts = () => {
    if (!tokenId) {
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${tokenId}`);
    const raw = JSON.stringify({
      query:
        "SELECT product_view.id, product_view.title, product_view.brand,  product_view.price_micros, product_view.currency_code, price_insights.suggested_price_micros,  price_insights.suggested_price_currency_code FROM PriceInsightsProductView",
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${GOOGEL_API_URL}`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("tokenID");
          toast.error("Token expired. Please Reload to create a new session.");
          setTokenId(null);
          setIsLoading(false);
          return;
        } else {
          return response.json();
        }
      })
      .then((result) => {
        if (result && result.results) {
          setProductData(result.results);
        } else {
          setProductData([]);
        }
      })
      .catch((error) => {
        toast.error(error);
        setIsLoading(false);
      });
  };

  const onSuccess = (googleUser) => {
    const authResponse = googleUser.getAuthResponse();
    const token = authResponse.access_token;
    localStorage.setItem("tokenID", token);
    setTokenId(token);
    setIsLoading(false);
    getProducts();
  };

  const onFailure = (error) => {
    setIsLoading(true);
    toast.error(error);
  };

  useEffect(() => {
    if (window.$ && window.$.fn.dataTAble) {
      if (dataTableInstance) {
        dataTableInstance.destroy();
      }
      const initializeTable = window.$("#child_rows").DataTable({
        responsive: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [10, 25, 50, 100],
        searching: true,
        ordering: true,
      });
      setDataTableInstance(initializeTable);
    } else {
      console.error("DataTable not found");
    }
    return () => {
      if (dataTableInstance) {
        dataTableInstance.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (dataTableInstance && Array.isArray(productData)) {
      dataTableInstance.clear();
      productData.forEach((item) => {
        const priceMicros = item.productView?.priceMicros || 0;
        const suggestedPriceMicros = item.priceInsights?.suggestedPriceMicros || 0;

        const diff = ((priceMicros - suggestedPriceMicros) / 1000000).toFixed(2);
        const badgeClass = diff > 0 ? "badge bg-danger" : "badge bg-success";

        dataTableInstance.row.add([
          "",
          item.productView?.title || "N/A",
          item.productView?.brand || "N/A",
          `$${(priceMicros / 1000000).toFixed(2)}`,
          `$${(suggestedPriceMicros / 1000000).toFixed(2)}`,
          `<span className="${badgeClass}">$${diff}</span>`,
          storeShopifyData[item.productView?.id]?.available_reno || "N/A",
          storeShopifyData[item.productView?.id]?.available_santa_fe || "N/A",
          `$${storeShopifyData[item.productView?.id]?.your_price || "N/A"}`,
          `${
            (
              (priceMicros - storeShopifyData[item.productView?.id]?.your_price * 1000000) /
              1000000
            ).toFixed(2) || "N/A"
          }`,
        ]);
      });
      dataTableInstance.draw();
    }
  }, [productData]);

  if (isCheckToken) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {(!tokenId && !isLoading) || isLoading === false ? (
        <div className="loginButton">
          <button onClick={handleClick}>
            <span>Click to Get Products data</span>
          </button>
        </div>
      ) : isLoading ? (
        tokenId && (
          <div className="row mt-2">
            <div className="col-12">
              <div className="card" style={{ overflowX: "auto" }}>
                <div className="card-body">
                  <h4 className="mt-0 header-title">Existing Products</h4>
                  <p className="text-muted mb-3">Data Table of Merchant Products from Shopify.</p>
                  <table
                    id="child_rows"
                    className="table table-striped table-bordered dt-responsive nowrap"
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: "0",
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Title</th>
                        <th>Brand</th>
                        <th>Price (USD)</th>
                        <th>Suggested Price (USD)</th>
                        <th>Current Price</th>
                        <th>Difference</th>
                        <th>Available Reno</th>
                        <th>Available Santa Fe</th>
                        <th>Your Price</th>
                        <th>Price Margin</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};
export default MerchantProducts;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataView from "../utils/DataView";
import LoadingScreen from "../Loading/loading";

const CategoryProducts = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { category_id, subcategory_id, manufacturer_id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [category_id, subcategory_id, manufacturer_id]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${serverUrl}/get_products.php?category_id=${category_id}&sub_category_id=${subcategory_id}&manufacturer_id=${manufacturer_id}`
      );
      const data = await response.json();
      if (data.error) {
        toast.error(data.error_msg);
        return;
      }
      setData(data.data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>{isLoading ? <LoadingScreen /> : <DataView productData={data} title="BTI Products" />}</>
  );
};

export default CategoryProducts;

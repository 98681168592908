import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { uploadShopifyProduct, uploadShopifyVariant } from "../../utils/functions";
import { FaTable } from "react-icons/fa";
import { IoGrid } from "react-icons/io5";
import { toast } from "react-toastify";

//Datatable imports
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";

//GridView imports
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ProductCard from "./card";
import Pagination from "@mui/material/Pagination";

const DataView = ({ productData, title }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [viewType, setViewType] = useState("table");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  //Datatable state variables
  const [globalFilter, setGlobalFilter] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    is_on_sale: { value: null, matchMode: FilterMatchMode.EQUALS },
    is_on_closeout: { value: null, matchMode: FilterMatchMode.EQUALS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    margin: { value: null, matchMode: FilterMatchMode.CONTAINS },
    msrp: { value: null, matchMode: FilterMatchMode.CONTAINS },
    your_price: { value: null, matchMode: FilterMatchMode.CONTAINS },
    current_price: { value: null, matchMode: FilterMatchMode.CONTAINS },
    map: { value: null, matchMode: FilterMatchMode.CONTAINS },
    info: { value: null, matchMode: FilterMatchMode.CONTAINS },
    item_description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sub_category_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    manufacturer_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  //GridView state variables
  const [filterOption, setFilterOption] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const updatedData = productData.map((item) => {
      if (item.product_id != null && item.variant_id != null) {
        item.status = "Uploaded";
      } else if (item.product_id != null && item.variant_id == null && item.available != 0) {
        item.status = "Upload";
      } else if (item.available > 0) {
        item.status = "Upload";
      } else {
        item.status = "Can't Upload";
      }

      if (item.msrp == 0) {
        item.msrp = (item.your_price / 0.6).toFixed(2);
      }

      if (item.msrp != null && item.your_price != null) {
        item.margin = `${(((item.msrp - item.your_price) / item.msrp) * 100).toFixed(2)}%`;
      } else {
        item.margin = "N/A";
      }
      return item;
    });
    setData(updatedData);
    if (data.length > 0) {
      toast.success("Products fetched successfully");
    }
  }, [productData]);

  const uploadProduct = async (rowData) => {
    setIsLoading(true);
    try {
      if (
        rowData.product_id === null &&
        rowData.variant_id === null &&
        rowData.available !== "0" &&
        rowData.permanent_deleted === "0"
      ) {
        let shopifyResponse = await uploadShopifyProduct(rowData);
        if (shopifyResponse.status === 201) {
          setData((prevData) =>
            prevData.map((item) => {
              if (item.id === rowData.id) {
                return {
                  ...item,
                  status: "Uploaded",
                  inventory_item_id: shopifyResponse.response.product.variants[0].inventory_item_id,
                  variant_id: shopifyResponse.response.product.variants[0].id,
                  product_id: shopifyResponse.response.product.id,
                };
              }
              if (item.group_id === rowData.group_id) {
                return {
                  ...item,
                  product_id: shopifyResponse.response.product.id,
                };
              }
              return item;
            })
          );
        }
      } else if (
        rowData.product_id !== null &&
        rowData.variant_id === null &&
        rowData.available !== "0" &&
        rowData.permanent_deleted === "0"
      ) {
        let shopifyResponse = await uploadShopifyVariant(rowData);
        if (shopifyResponse.status === 200) {
          setData((prevData) =>
            prevData.map((item) => {
              if (item.id === rowData.id) {
                return {
                  ...item,
                  status: "Uploaded",
                  inventory_item_id: shopifyResponse.response.variant.inventory_item_id,
                  variant_id: shopifyResponse.response.variant.id,
                  product_id: shopifyResponse.response.variant.product_id,
                };
              }
              return item;
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadProducts = async () => {
    selectedProducts.forEach(async (product) => {
      await uploadProduct(product);
    });
    setSelectedProducts([]);
  };

  //Datatable functions
  const statusTemplate = (rowData) => {
    switch (rowData.status) {
      case "Uploaded":
        return <span className={"bg-success text-white p-1 btn btn-lg"}>{rowData.status}</span>;
      case "Upload":
        return (
          <Button
            label="Upload"
            className="btn btn-dark btn-lg"
            onClick={() => uploadProduct(rowData)}
            disabled={isLoading}
          />
        );
      case "Can't Upload":
        return <span className={"bg-danger text-white p-1 btn btn-lg"}>{rowData.status}</span>;
      default:
        return <span className={"bg-secondary text-white p-1 btn btn-lg"}>{rowData.status}</span>;
    }
  };
  const priceTemplate = (rowData) => `$${rowData.your_price}`;
  const stockTemplate = (rowData) =>
    rowData.available !== "0" ? (
      <span className="btn btn-lg bg-success text-white">In Stock: {rowData.available}</span>
    ) : (
      <span className="btn btn-lg bg-danger text-white">Out of Stock</span>
    );
  const saleTemplate = (rowData) =>
    rowData.is_on_sale === "1" ? (
      <span className="btn btn-lg bg-success text-white">Yes</span>
    ) : (
      <span className="btn btn-lg bg-danger text-white">No</span>
    );
  const closeoutTemplate = (rowData) =>
    rowData.is_on_closeout === "1" ? (
      <span className="btn btn-lg bg-success text-white">Yes</span>
    ) : (
      <span className="btn btn-lg bg-danger text-white">No</span>
    );
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`http://www.bti-usa.com/images/pictures${rowData.image_path}`}
        alt={rowData.image}
        width="325px"
        className="shadow-4"
      />
    );
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value.trimStart().replace(/\s+/g, " ");
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilter(value);
  };
  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={["Uploaded", "Upload", "Can't Upload"]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select..."
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };
  const booleanRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={[
          { label: "Yes", value: 1 },
          { label: "No", value: 0 },
        ]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select..."
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  //GridView functions
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };
  //   const handleFilterChange = (event) => {
  //     setFilterOption(event.target.value);
  //     setCurrentPage(1);
  //   };
  const filteredData = data.filter((product) => {
    if (filterOption === "All") return true;
    if (filterOption === "Sale") return product.is_on_sale === "1";
    if (filterOption === "Closeout") return product.is_on_sale === "0";
    return true;
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const checkboxChange = (event, rowData) => {
    if (event.target.checked) {
      setSelectedProducts([...selectedProducts, rowData]);
    } else {
      setSelectedProducts(selectedProducts.filter((product) => product.id !== rowData.id));
    }
  };

  return (
    <div className="row mt-2">
      <div className="col-12">
        <div className="card" style={{ overflowX: "auto" }}>
          <div className="card-body">
            <h4 className="mt-0 header-title">{title}</h4>
            <div className="row mb-3">
              <div className="col-md-6">
                {selectedProducts.length !== 0 && viewType === "table" && (
                  <Button
                    label="Upload Selected"
                    className="btn btn-dark btn-lg"
                    disabled={isLoading}
                    onClick={() => uploadProducts}
                  />
                )}
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <Button className="mr-2 btn btn-dark btn-lg" onClick={() => setViewType("table")}>
                  <FaTable />
                </Button>
                <Button className="mr-2 btn btn-dark btn-lg" onClick={() => setViewType("grid")}>
                  <IoGrid />
                </Button>
              </div>
            </div>
            {viewType === "table" ? (
              <>
                <div className="mb-3 flex justify-end">
                  <span className="p-input-icon-left">
                    <InputText
                      value={globalFilter}
                      onChange={onGlobalFilterChange}
                      placeholder="Search Category Products..."
                      className="p-inputtext-sm"
                    />
                  </span>
                </div>
                <DataTable
                  value={data}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  globalFilter={globalFilter}
                  filters={filters}
                  filterDisplay="row"
                  emptyMessage="No products found."
                  columnResizeMode="expand"
                  resizableColumns
                  showGridlines
                  selectionMode={"checkbox"}
                  selection={selectedProducts}
                  onSelectionChange={(e) => setSelectedProducts(e.value)}
                >
                  <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                  <Column header="Image" body={imageBodyTemplate} />
                  <Column
                    sortable
                    field="status"
                    header="On Website"
                    showFilterMenu={false}
                    filter
                    filterElement={statusRowFilterTemplate}
                    body={statusTemplate}
                  />
                  <Column
                    sortable
                    field="is_on_sale"
                    header="On Sale"
                    showFilterMenu={false}
                    filter
                    filterElement={booleanRowFilterTemplate}
                    body={saleTemplate}
                  />
                  <Column
                    sortable
                    field="is_on_closeout"
                    header="On Closeout"
                    showFilterMenu={false}
                    filter
                    filterElement={booleanRowFilterTemplate}
                    body={closeoutTemplate}
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="id"
                    header="Product ID"
                    body={(rowData) => {
                      return (
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/product?id=" + rowData.id);
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {rowData.id}
                        </a>
                      );
                    }}
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="margin"
                    header="Margin"
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="msrp"
                    header="Msrp"
                    body={(rowData) => `$${rowData.msrp}`}
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="your_price"
                    header="Your Price"
                    body={priceTemplate}
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="current_price"
                    header="Current Price"
                    className="no-wrap"
                    body={(rowData) => `$${rowData.current_price}`}
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="map"
                    header="MAP"
                    body={(rowData) => `$${rowData.map}`}
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="info"
                    header="Info"
                    body={stockTemplate}
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="item_description"
                    header="Product Name"
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="category_name"
                    header="Category"
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="sub_category_name"
                    header="Subcategory"
                  />
                  <Column
                    sortable
                    filter
                    filterPlaceholder="Search..."
                    field="manufacturer_name"
                    header="Manufacturer"
                  />
                </DataTable>
              </>
            ) : (
              <>
                <FormControl variant="outlined" sx={{ mb: 4, minWidth: 120 }}>
                  <InputLabel id="items-per-page-label">Items per page</InputLabel>
                  <Select
                    labelId="items-per-page-label"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    label="Items per page"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {currentItems.map((product) => (
                      <Grid item xs={12} lg={4} md={6} key={product.id}>
                        <ProductCard
                          data={product}
                          isChecked={selectedProducts.find((p) => p.id === product.id)}
                          handleCheckboxChange={(e) => checkboxChange(e, product)}
                          isLoading={isLoading}
                          handleButtonClick={() => uploadProduct(product)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{ mt: 4, display: "flex", justifyContent: "center", marginBottom: "20px" }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataView;

import React, { useState } from "react";
import Card from "@mui/material/Card";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { Button } from "primereact/button";

const ProductCard = ({ data, isChecked, handleCheckboxChange, isLoading, handleButtonClick }) => {
  const [expandMore, setExpandMore] = useState(false);

  return (
    <Card
      sx={{
        maxWidth: 345,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <div className="row">
        <div className="col-lg-12">
          {/* Header Section */}
          <div className="card-body product-info d-flex justify-content-between">
            <h4
              style={{
                maxHeight: "60px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {data?.item_description}
            </h4>
          </div>
          <div className="ml-4">
            <p>
              <strong>Your Price:</strong> ${data.your_price}
            </p>
            <p>
              <strong>MSRP:</strong> ${data.msrp}
            </p>
            <p>
              <strong>Margin:</strong> ${parseFloat(data.msrp - data.your_price).toFixed(2)}
            </p>
          </div>
          {/* {data.status === "Upload" && (
            <input
              type="checkbox"
              className="form-check-input ml-3 btn-lg"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          )} */}
          <p className="mb-2 ml-4">Product ID: {data?.id}</p>

          {/* Image Section */}
          <div
            className="card e-co-product"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f8f9fa",
              height: "350px",
              overflow: "hidden",
            }}
          >
            <img
              src={`http://www.bti-usa.com/images/pictures${data.image_path}`}
              alt={data?.id}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>

          {expandMore && (
            <>
              {/* Badge Section */}
              <div className="col-lg-12 align-self-center mb-2">
                {data.is_on_sale === "1" ? (
                  <span className="btn btn-lg bg-success p-2 text-white">Sale</span>
                ) : (
                  <span className="btn btn-lg bg-danger p-2 text-white">Closeout</span>
                )}
              </div>

              {/* Details Section */}
              <div className="col-lg-12 align-self-center">
                <div className="single-pro-detail">
                  <p className="text-muted mb-0">{data.group_text}</p>
                  <p className="text-muted mb-0">
                    <strong style={{ textDecoration: "underline" }}>
                      <a href={`https://www.bti-usa.com/public/item/${data?.id}?track=true`}>
                        View on BTI
                      </a>
                    </strong>
                  </p>
                  <ul className="list-unstyled pro-features border-0">
                    <li>
                      <strong>Sale Starts On:</strong> {data.sale_starts_on}
                    </li>
                    <li>
                      <strong>Sale Ends On:</strong> {data.sales_ends_on}
                    </li>
                    <li>
                      <strong>Current Price:</strong> ${data.current_price}
                    </li>
                    <li>
                      <strong>Your Price:</strong> ${data.your_price}
                    </li>
                    <li>
                      <strong>MSRP:</strong> ${data.msrp}
                    </li>
                    <li>
                      <strong>MAP:</strong> ${data.map}
                    </li>
                    <li>
                      <strong>Regular Price:</strong> ${data.regular_price}
                    </li>
                    <li>
                      <strong>Margin:</strong> ${parseFloat(data.msrp - data.your_price).toFixed(2)}
                    </li>
                    <li>
                      <strong>Oversize Surcharge:</strong> ${data.oversize_surcharge}
                    </li>
                    <li>
                      <strong>Total in Stock:</strong> {data.available}
                    </li>
                    <li>
                      <strong>Available at Reno:</strong> {data.available_reno}
                    </li>
                    <li>
                      <strong>Available at Santa Fe:</strong> {data.available_santa_fe}
                    </li>
                  </ul>
                  {/* {data.status === "Upload" && (
                    <Button
                      label="Upload"
                      className="btn btn-dark btn-lg"
                      disabled={isLoading}
                      onClick={handleButtonClick}
                    />
                  )} */}
                </div>
              </div>
            </>
          )}
          <Button
            label={expandMore ? "Less" : "More"}
            className="btn btn-dark btn-sm w-100"
            onClick={() => setExpandMore(!expandMore)}
          />
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;

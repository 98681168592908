import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./slice"


const store = configureStore({
  reducer: {
    category: categoryReducer
  },
});

export default store;
